<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field
          v-model="product.i"
          label="Product id"
          :counter="max"
          required
        ></v-text-field>
        <v-combobox
          v-model="productTypeSelect"
          :items="productTypes"
          item-value="id"
          item-text="value"
          hide-details
          label="Product Type"
        ></v-combobox>
        <v-text-field
          v-model="product.p"
          label="Price"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="product.c"
          label="Value"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="product.s"
          label="ShopCard"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="product.g"
          label="Segment"
          type="number"
          :counter="max"
          required
        ></v-text-field>

        <side-detail-list-card ref="detail" />
      </v-flex>

      <v-btn color="success" @click="save">Save</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import router from "@/router";
import SideDetailListCard from "./sideProducts/SideDetailListCard.vue";
import productType from "@/lib/productType";

export default {
  components: { SideDetailListCard },
  data: () => ({
    product: {},
    alert: false,

    max: 120,
    productTypeSelect: { id: 0, value: "Chip" },
    productTypes: productType,
  }),

  methods: {
    async save() {
      this.product.c = parseInt(this.product.c || 0);
      // this.product.p = this.product.p.replace(',',".");
      this.product.s = parseInt(this.product.s || 0);
      this.product.g = parseInt(this.product.g || 0);
      this.product.t = this.productTypeSelect.key;
      await this.$store.dispatch("products/createOrUpdate", this.product);
      router.push("/products");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
  async created() {
    this.product = {};
    if (this.$route.params.id) {
      await this.$store.dispatch("products/get", this.$route.params.id);
      this.product = this.$store.state.products.detail;
      this.productTypeSelect = this.productTypes.find(
        (m) => m.key == this.product.t
      );
      this.$refs.detail.initialize(this.product.i);
    }
  },
};
</script>
